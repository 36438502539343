import * as UnitMapActions from './unit-map.actions';
import * as CoreActions from '@common/_store/core.actions';
import {createEntityAdapter} from '@ngrx/entity';
import {TruckLocationModel} from '../../../../../services/broker/model/truckLocationModel';
import {EntityStateModel} from '@common/_models/core.models';
import {createReducer, on} from '@ngrx/store';
import {TruckLocationRequest} from '../../../../../services/broker/model/truckLocationRequest';

export const unitMapFeatureKey = 'unitMap';

export const unitMapAdapter = createEntityAdapter<TruckLocationModel>({
    selectId: model => model.latitude + " " + model.longitude
});

export const initialState: UnitMapState = unitMapAdapter.getInitialState({
    pagination: {},
    listLoading: 'listLoading',
    itemsLoading: []
});

export type UnitMapState = EntityStateModel<TruckLocationModel, TruckLocationRequest>;

export const unitMapReducer = createReducer(
    initialState,

    on(UnitMapActions.LoadList, (state, action) => {
        return {
            ...state,
            listLoading: action.payload.loading
        };
    }),

    on(UnitMapActions.LoadListSuccess, (state, action) => {
        if (action.payload.clearState) {
            return unitMapAdapter.setAll(action.payload.items, {
                ...state,
                listLoading: undefined,
            });
        }

        return unitMapAdapter.upsertMany(action.payload.items, {
            ...state,
            listLoading: undefined,
        });
    }),

    on(UnitMapActions.LoadListFailure, (state) => {
        return {
            ...state,
            listLoading: undefined,
        };
    }),

    on(UnitMapActions.SetFilter, (state, action) => {
        return {
            ...state,
            filter: action.payload.filter
        };
    }),

    on(UnitMapActions.SetListLoading, (state, action) => {
        return {
            ...state,
            listLoading: action.payload.loading
        };
    }),

    on(UnitMapActions.SetInitialState, () => {
        return {
            ...initialState
        };
    }),

    on(CoreActions.LogOutSuccess, () => ({...initialState}))
);
